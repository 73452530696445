(function($) {
  $(function() {
    $('.js-accordion').on('click', function(e) {
      e.preventDefault();

      var $this = $(this);
      var $accordion = $this.parent();
      var $panel = $this.next();

      if ($accordion.hasClass('c-accordion__section--active')) {
        $accordion.removeClass('c-accordion__section--active');
        $panel.css('max-height', 0);
      }
      else {
        $accordion.addClass('c-accordion__section--active');
        $panel.css('max-height', $panel.get(0).scrollHeight + 'px');
      }
    });
  });
})(jQuery);
